import '../pages/Join.css';
import Event from '../components/Event';
import { HashLink as Link } from "react-router-hash-link";

import why1 from '../images/join/why1.png';
import why2 from '../images/join/why2.png';
import why3 from '../images/join/why3.png';

export default function Join() {
    return(
        <div>
            <div id="apply">
                <svg id="applyGradient" width="900px" height="300px">
                    <defs>
                        <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="95%">
                            <stop offset="0%" style={{stopColor:"white", stopOpacity:0.5}} />
                            <stop offset="100%"  style={{stopColor:"#E7E1DC", stopOpacity:1}} />
                        </linearGradient>
                    </defs>
                    <polygon points="0,0 0,300 726.7949,300 900,0" stroke="transparent" fill="url(#grad1)"/>
                </svg>
                <svg id="applyGradientBig" width="900px" height="300px" viewBox="0 0 900 300">
                    <defs>
                        <linearGradient id="grad2" x1="0%" y1="0%" x2="0%" y2="95%">
                            <stop offset="0%" style={{stopColor:"white", stopOpacity:0.5}} />
                            <stop offset="100%" style={{stopColor:"#E7E1DC", stopOpacity:1}}/>
                        </linearGradient>
                    </defs>
                    <polygon points="0,0 0,300 726.7949,300 900,0" stroke="transparent" fill="url(#grad2)"/>
                </svg>
                <h1>Apps Open</h1>
                <svg id="applyButton" viewBox="0 0 225 50">
                    <a href="https://tinyurl.com/deltafa24">    
                        <polygon points="0,0 0,50 196.1325,50 225,0" stroke="transparent" fill="#23543B"/>
                    </a>
                </svg>
                <a href="https://tinyurl.com/deltafa24" id="actualApply">Apply Here</a>
                {/* <svg id="cheatButton" viewBox="0 0 225 50">
                    <Link to='/cheatsheet'>
                        <polygon points="28.8675,0 0,50 196.1325,50 225,0" stroke="transparent" fill="#23543B"/>
                    </Link>
                </svg>
                <Link to='/cheatsheet' id="actualCheat">CHEAT SHEET</Link> */}
                {/* <svg id="smallAB" viewBox="0 0 130 50">
                    <Link to='/app'>
                        <polygon points="0,0 0,50 101.1325,50 130,0" stroke="transparent" fill="#23543B"/>
                    </Link>
                </svg>
                <Link to='/app' id="smallAA">APP LINK</Link> */}
                <svg id="smallAB" viewBox="0 0 225 50">
                    <a href="https://tinyurl.com/deltafa24">
                        <polygon points="0,0 0,50 196.1325,50 225,0" stroke="transparent" fill="#23543B"/>
                    </a>
                </svg>
                <a href="https://tinyurl.com/deltafa24" id="smallAA">Apps Open</a>
                {/* <svg id="smallCB" viewBox="0 0 180 50">
                    <a href="files/cheatsheet.pdf">
                        <polygon points="28.8675,0 0,50 151.1325,50 180,0" stroke="transparent" fill="#23543B"/>
                    </a>
                </svg>
                <a href="files/cheatsheet.pdf" id="smallAC">CHEAT SHEET</a> */}
            </div>
            <div id="whyDelta">
                <h3>Why Delta?</h3>
                <p>Our people, culture, and focus redefine what it means to be a consulting
                    club here at Cal. It’s not just about being professional and providing
                    great consulting services, but is also about caring about those around
                    you. It’s about being inspired and eventually becoming someone who can
                    inspire others. It’s about developing a sense of community by being in
                    the first and best educational consulting club at the University of
                    California, Berkeley.
                </p>
                <div id="threeReasons">
                    <div className="reason" id="people">
                        <div className="whyIcons"><img src={why1} alt="People Icon"/></div>
                        <h3>People</h3>
                        <p>The people in our club are those who you want to have brunch with
                            Sunday morning. They are the ones you can work out or play sports
                            with after class. The ones you want to talk to anytime you have
                            questions or concerns.</p>
                    </div>
                    <div className="reason" id="culture">
                        <div className="whyIcons"><img src={why2} alt="Culture Icon"/></div>
                        <h3>Culture</h3>
                        <p>No toxicity involved. We are passionate individuals who all care
                            about the mission of the club. Disregarding majors and backgrounds,
                            we want those who can contribute to this special type of environment
                            we have fostered.</p>
                    </div>
                    <div className="reason" id="focus">
                        <div className="whyIcons"><img src={why3} alt="Focus Icon"/></div>
                        <h3>Focus</h3>
                        <p>We are a consulting club with an emphasis on education. With a
                            specific focus unlike any other consulting club, our projects bring
                            high value to both our members and the companies we serve.</p>
                    </div>
                </div>
            </div>
            <div id="timeline">
                <h1>Fall 2024 Recruitment Timeline</h1>
                {/* Coffee Chats: Get an edge in the recruitment process! <a href="https://tinyurl.com/deltafa23cc">Sign up</a> for a 1 on 1 coffee chat with a Delta member to learn more about this upcoming Spring semester, get a better feel of our culture, or just talk about life. */}
                {/* Infosessions: Come meet our members and learn about the awesome opportunities we have to offer for all majors, years, backgrounds, and experience levels. We'll share some of our past projects, socials, and favorite memories.  Join us on this <a href="https://berkeley.zoom.us/s/9850651934">Zoom link</a>. */}
                {/* Case Workshop: Our case workshop is meant to help walk you through the case portion of our interview and what to expect from the overall interview process. This includes best practices, a sample case, and time to ask questions! You will also have the opportunity to ask us any questions about your application before the deadline. Join us on this <a href="https://berkeley.zoom.us/s/9850651934">Zoom link</a>. */}
                {/* Group Interviews: After we review online applications, some applicants will be invited to first round group interviews. You will be in a group of 3-4 applicants tasked with working together to solve a case. This interview will last roughly 30 minutes. */}
                {/* Individual Interviews: Lastly, we conduct one hour final round interviews for candidates who proceed past the first round and social night. These will have more behavioral questions and include a longer, more complex case. */}
                <Event date="August 28" time="Wed | 12:00am" title="Applications Open"
                    text={[<a href='https://tinyurl.com/deltafa24'>Sign Up</a>, " to be a part of our Fall 2024 Analyst cohort! Analysts engage in semester-long consulting projects with clients in the education technology industry, have access to personal and professional development opportunities, and gain a family on campus."]}/>
                <Event date="August 28 - September 5" time="Wed - Next Thur" title="Coffee Chats"
                   text={[<a href='https://tinyurl.com/deltacoffee'>Sign Up</a>, " for a 1 on 1 coffee chat with a Delta member to learn more about this upcoming semester, get a better feel of our culture, or just talk about life."]}/>
                <Event date="September 3" time="Tue | 8:00-9:00pm" title="Info Session 1 @ Social Sciences 20"
                    text={["Come meet our members and learn about the amazing opportunities we have to offer for all majors, years, backgrounds, and experience levels. We'll share some of our past projects and favorite memories, with a networking session following our presentation. Join us in SOCS 20."]}/>
                <Event date="September 3" time="Tue | 9:00-10:00pm" title="DEI in Business Night"
                    text={["Come to a joint club session where we discuss the importance of Diversity Equity and Inclusion in Business!"]}/>
                <Event date="September 4" time="Wed | 8:00-9:00pm" title="Info Session 2 @ TBD"
                    text={["Come meet our members and learn about the amazing opportunities we have to offer for all majors, years, backgrounds, and experience levels. We'll share some of our past projects and favorite memories, with a networking session following our presentation."]}/>
                <Event date="September 4" time="Wed | 8:00-10:00pm" title="Women's Night"
                    text={["All Women and Non-Binary students are welcome to come!"]}/>
                <Event date="September 5" time="Thurs | 8:00-9:00pm" title="URM Resume Workshop @ Social Science 60"
                    text={["All individuals who are Underrepresented Minorities (URMs) are welcome to join the Resume Workshop in SOCS 60."]}/>
                <Event date="September 5" time="Thurs | 9:00-10:00pm" title="Case Workshop @ Social Sciences 60"
                    text="All applicants are invited to attend our case workshop, which is meant to help walk you through the case portion of our interview and what to expect from the overall interview process. This includes best practices, a sample case, and time to ask questions!"/>
                 <Event date="September 5" time="Thurs | 11:59pm" title="Applications Close"
                    text={["Head on over to our applicant portal ", <Link to='/join'></Link>, " to start your application. We look forward to reviewing it!"]}/>
                
                
                {/*<Event date="January 27" time="Sat | TBD" title="Group Interview @Chou Hall" inviteOnly="yes"
                    text="After we review online applications, some applicants will be invited to first round group interviews. You will be in a small group of applicants tasked with working together to solve a business case."/>
                <Event date="January 28" time="Sun | TBD" title="Individual Interview @Social Sciences Building" inviteOnly="yes"
                    text="After we review group interviews, some applicants will be invited to individual interviews, which will consist of both a behavioral and a market sizing portion."/>
                <Event date="January 28" time="Sun | 5:00 - 6:30pm" title="Social Round" inviteOnly="yes"
                    text="All applicants in the individual interview round will also attend a social night the same evening. Get to know us in an informal setting and get a better sense of what it feels like to be part of our organization."/>*/}
            </div>


            <div id="timeline">
                <h1>Resources</h1>
                {/* Coffee Chats: Get an edge in the recruitment process! <a href="https://tinyurl.com/deltafa23cc">Sign up</a> for a 1 on 1 coffee chat with a Delta member to learn more about this upcoming Spring semester, get a better feel of our culture, or just talk about life. */}
                {/* Infosessions: Come meet our members and learn about the awesome opportunities we have to offer for all majors, years, backgrounds, and experience levels. We'll share some of our past projects, socials, and favorite memories.  Join us on this <a href="https://berkeley.zoom.us/s/9850651934">Zoom link</a>. */}
                {/* Case Workshop: Our case workshop is meant to help walk you through the case portion of our interview and what to expect from the overall interview process. This includes best practices, a sample case, and time to ask questions! You will also have the opportunity to ask us any questions about your application before the deadline. Join us on this <a href="https://berkeley.zoom.us/s/9850651934">Zoom link</a>. */}
                {/* Group Interviews: After we review online applications, some applicants will be invited to first round group interviews. You will be in a group of 3-4 applicants tasked with working together to solve a case. This interview will last roughly 30 minutes. */}
                {/* Individual Interviews: Lastly, we conduct one hour final round interviews for candidates who proceed past the first round and social night. These will have more behavioral questions and include a longer, more complex case. */}
                <iframe width="1120" height="630" src="https://www.youtube.com/embed/lWY0cp_Nmf8?si=-pgrDzX7Do5qjlFn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                {/*<Event date="January 27" time="Sat | TBD" title="Group Interview @Chou Hall" inviteOnly="yes"
                    text="After we review online applications, some applicants will be invited to first round group interviews. You will be in a small group of applicants tasked with working together to solve a business case."/>
                <Event date="January 28" time="Sun | TBD" title="Individual Interview @Social Sciences Building" inviteOnly="yes"
                    text="After we review group interviews, some applicants will be invited to individual interviews, which will consist of both a behavioral and a market sizing portion."/>
                <Event date="January 28" time="Sun | 5:00 - 6:30pm" title="Social Round" inviteOnly="yes"
                    text="All applicants in the individual interview round will also attend a social night the same evening. Get to know us in an informal setting and get a better sense of what it feels like to be part of our organization."/>*/}
            </div>


        </div>

                    
    );
}
